<template>
  <search-page ref="searchPageRef" />
</template>
<script>
import SearchPage from '@/pages/Search/pages'
export default {
  name: 'big-data',
  components: {
    SearchPage
  }
}
</script>
